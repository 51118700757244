import React from "react"

function OriginsSchema() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="474.363"
      height="422.392"
      version="1.1"
      viewBox="0 0 474.363 422.392"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
          <path d="M0 316.794h355.772V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath34" clipPathUnits="userSpaceOnUse">
          <path d="M0 316.794h355.772V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath62" clipPathUnits="userSpaceOnUse">
          <path d="M0 316.794h355.772V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath90" clipPathUnits="userSpaceOnUse">
          <path d="M0 316.794h355.772V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath124" clipPathUnits="userSpaceOnUse">
          <path d="M0 316.794h355.772V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 422.392)">
        <g>
          <g clipPath="url(#clipPath18)">
            <g transform="translate(147.753 250.576)">
              <path
                fill="#014486"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-28.279-29.033-51.203-64.847-51.203-35.814 0-64.847 22.924-64.847 51.203 0 28.279 29.033 51.203 64.847 51.203C-29.033 51.203 0 28.279 0 0"
              ></path>
            </g>
          </g>
        </g>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#99b4cf"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="8.406"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="matrix(1 0 0 -1 49.108 259.57)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 4.783071 6.9014082 10.860682 14.727488 18.749805 20.683208 24.550014 28.685816 32.645088 35.308983 39.268257 43.135063 45.715733 49.738052 52.524673 54.643013 58.459381 61.040054 63.15839 67.0252"
            y="0"
          >
            Place characteristics
          </tspan>
        </text>
        <g>
          <g clipPath="url(#clipPath34)">
            <g transform="translate(37.975 244.392)">
              <path
                fill="none"
                stroke="#a4bcd3"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="2"
                d="M0 0s-54.569 74.916-31.121 71.273c16.077-2.498 34.522-20.711 44.31-31.631"
              ></path>
            </g>
            <g transform="translate(44.003 280.772)">
              <path
                fill="#a4bcd3"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l14.112-4.954-2.764 14.699z"
              ></path>
            </g>
            <g transform="translate(132.544 235.002)">
              <path
                fill="none"
                stroke="#a4bcd3"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="2"
                d="M0 0s47.994-49.929 27.708-50.245c-14.152-.221-35.325 17.849-46.557 28.498"
              ></path>
            </g>
            <g transform="translate(120.545 217.112)">
              <path
                fill="#a4bcd3"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-14.483 3.731 4.008-14.409z"
              ></path>
            </g>
          </g>
        </g>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="8.406"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="matrix(1 0 0 -1 37.998 233.59)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.27385 6.4096513 10.579077 12.697414 16.471752 18.59009 22.759516 26.895317 30.854589 32.972927 33.908527 37.724895 39.658298 43.525105 47.660904 51.620178 54.284073 58.243343 62.110149 64.690826 68.713142 71.503967 73.622307 77.438675 80.019348 82.137688 86.004494"
            y="0"
          >
            Individual’s characteristics
          </tspan>
        </text>
        <g>
          <g clipPath="url(#clipPath62)">
            <g transform="translate(125.07 227.63)">
              <path
                fill="none"
                stroke="#99b4cf"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="2"
                d="M0 0h-84.43a7.66 7.66 0 00-7.659 7.66v3.077a7.659 7.659 0 007.659 7.659H0a7.66 7.66 0 007.66-7.659V7.66A7.66 7.66 0 000 0z"
              ></path>
            </g>
            <g transform="translate(158.681 248.765)">
              <path
                fill="none"
                stroke="#a4bcd3"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="2"
                d="M0 0h61.868"
              ></path>
            </g>
            <g transform="translate(218.36 241.285)">
              <path
                fill="#a4bcd3"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l12.952 7.479L0 14.958z"
              ></path>
            </g>
            <g transform="translate(345.303 238.296)">
              <path
                fill="#014486"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-92.594c-5.782 0-10.469 4.687-10.469 10.469 0 5.782 4.687 10.469 10.469 10.469H0c5.782 0 10.469-4.687 10.469-10.469C10.469 4.687 5.782 0 0 0"
              ></path>
            </g>
          </g>
        </g>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="9.568"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="matrix(1 0 0 -1 263.51 245.955)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 5.4439645 10.022061 14.786726 19.522688 21.933723 26.212355 27.278185 31.621874 33.822422 39.878712 44.45681 46.867844 49.278881 52.751919 57.487881 62.070763 64.481796 69.189056"
            y="0"
          >
            People’s well-being
          </tspan>
        </text>
        <g>
          <g clipPath="url(#clipPath90)">
            <g transform="translate(280.48 211.362)">
              <path
                fill="none"
                stroke="#a4bcd3"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="2"
                d="M0 0l-46.955-55.879"
              ></path>
            </g>
            <g transform="translate(273.345 214.499)">
              <path
                fill="#a4bcd3"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l14.058 5.105-2.606-14.728z"
              ></path>
            </g>
            <g transform="translate(229.206 161.97)">
              <path
                fill="#a4bcd3"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-2.607-14.727 14.059 5.104z"
              ></path>
            </g>
          </g>
        </g>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#000"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="matrix(1 0 0 -1 24.436 183.422)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 1.355 3.8150001 6.1149998 7.375 9.835 12.315 13.575 16.035 18.549999 19.700001 22.215 24.665501 26.9105 29.3055 30.9655 33.425499 35.780499 38.240501 40.540501 42.935501 44.085499 46.440498 48.900501 51.380501 52.530499 55.005501 57.495499 58.755501 60.015499 62.315498 64.545502 65.695503 67.155998 69.646004 72.136002 73.396004 75.666"
            y="0"
          >
            Including governance and policy tools,
          </tspan>
          <tspan
            x="-7.4650002 -5.1950002 -2.7349999 -0.435 2.0250001 3.175 5.5300002 7.8000002"
            y="8"
          >
            such as{" "}
          </tspan>
          <tspan
            x="8.9549999 11.8 14.26 16.735001 17.995001 19.254999 21.555 22.705 24.975 27.370001 29.67 31.1325 33.622501 35.282501 36.432499 37.692501 40.1525 42.612499 45.063499 47.272999 49.627998 51.162998 52.423 54.912998 57.373001"
            y="8"
          >
            Public sector innovation{" "}
          </tspan>
          <tspan x="58.522999 60.877998 63.338001 65.818001" y="8">
            and{" "}
          </tspan>
          <tspan
            x="66.968002 69.792999 72.148003 73.682999 76.078003 77.227997 79.688004 81.958"
            y="8"
          >
            Date use
          </tspan>
          <tspan
            x="84.120003 86.964996 89.360001 91.849998 94.324997 95.584999 97.816498 98.373001 100.643 101.793 104.958 107.353 108.613 109.873 111.688 114.163 116.558 117.818 120.278 122.793 123.943 125.203 127.473 128.623 130.923 133.41299 137.123 139.59801 142.088 144.358 146.75301 149.233 150.383 152.873 154.513 155.66299 159.373 161.728 164.188 166.418 167.56799 170.048 171.308 175.01801 177.41299 179.873 182.14301 183.403 185.89301 188.353"
            y="168.885"
          >
            People’s well-being is composed of many dimensions
          </tspan>
        </text>
        <g>
          <g clipPath="url(#clipPath124)">
            <text
              style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
              fill="#000"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              fontFamily="Roboto Condensed"
              fontSize="5"
              fontStretch="condensed"
              fontVariant="normal"
              fontWeight="bold"
              transform="matrix(1 0 0 -1 99.19 1.355)"
              writingMode="lr-tb"
            >
              <tspan
                x="0 2.8640001 5.1090002 7.5040002 9.0869999 11.442 13.957 16.351999 17.502001 18.761999 21.120501 23.365499 25.7605 27.0205 29.290501 30.4405 32.795502 35.255501 37.7355 38.885502 41.365501 42.6255 44.8955 46.4305 48.0905 49.350498 51.8255 54.2855 55.820499 57.080502 59.570499 62.030499 64.300499 65.4505 67.805496 70.105499 71.675003 74.165001 76.434998 78.705002 79.855003 82.370003 83.940498 86.430496 88.890503 91.365501 93.635498 94.7855 97.275497 98.915497 100.0655 102.5405 104.9355 107.4255 109.9005 111.1605 113.5555 114.7055 117.8705 119.1305 120.6655 123.1255 124.3855 126.8455 127.9955 130.2955 131.5555 133.0905 134.35049 136.7455"
                y="0"
              >
                Average levels and distributions across groups of people within
                cities
              </tspan>
            </text>
            <g transform="translate(137.898 45.42)">
              <path
                fill="#014486"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l21.712 44.963c.113.234-.153.468-.371.326l-40.667-26.49c-2.163-.563-3.647 2.087-2.05 3.661l40.454 25.927c.216.139.118.473-.138.473l-46.292-.015c-2.11.693-1.917 3.742.267 4.213l45.711-.026c.252 0 .354.325.146.468l-36.771 25.382c-1.336 1.665.406 4.053 2.408 3.3l36.228-24.714c.205-.14.47.065.386.299L6.231 99.05c-.216 2.134 2.53 3.215 3.794 1.494l15.038-42.409c.085-.238.426-.224.49.02l11.81 44.883c.973 1.925 3.851 1.357 3.976-.786l-11.95-44.306c-.067-.246.228-.428.419-.258l35.449 31.739c1.924 1.147 4.099-.969 3.005-2.924L32.643 54.818c-.19-.173 1.632.225 1.884.268l46.92 7.872c2.227-.068 2.9-3.038.93-4.103l-48.642-8.051c-.253-.046 1.572-.857 1.811-.953L80.66 31.768c1.822-1.262.779-4.143-1.442-3.98L32.716 46.849c-.241.096.497-1.22.652-1.427L63.144 5.76c.839-2.055-1.589-3.933-3.356-2.594L29.204 44.042c-.157.207-.197-.913-.169-1.172l5.426-49.679c-.41-2.196-3.453-2.474-4.204-.384L25.51 43.046c-.027.259-.85-.144-.96-.379L3.717-1.949C2.188-3.588-.502-2.178 0 0"
              ></path>
            </g>
            <g transform="translate(137.898 45.42)">
              <path
                fill="none"
                stroke="#014486"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeOpacity="1"
                strokeWidth="9"
                d="M0 0l21.712 44.963c.113.234-.153.468-.371.326l-40.667-26.49c-2.163-.563-3.647 2.087-2.05 3.661l40.454 25.927c.216.139.118.473-.138.473l-46.292-.015c-2.11.693-1.917 3.742.267 4.213l45.711-.026c.252 0 .354.325.146.468l-36.771 25.382c-1.336 1.665.406 4.053 2.408 3.3l36.228-24.714c.205-.14.47.065.386.299L6.231 99.05c-.216 2.134 2.53 3.215 3.794 1.494l15.038-42.409c.085-.238.426-.224.49.02l11.81 44.883c.973 1.925 3.851 1.357 3.976-.786l-11.95-44.306c-.067-.246.228-.428.419-.258l35.449 31.739c1.924 1.147 4.099-.969 3.005-2.924L32.643 54.818c-.19-.173 1.632.225 1.884.268l46.92 7.872c2.227-.068 2.9-3.038.93-4.103l-48.642-8.051c-.253-.046 1.572-.857 1.811-.953L80.66 31.768c1.822-1.262.779-4.143-1.442-3.98L32.716 46.849c-.241.096.497-1.22.652-1.427L63.144 5.76c.839-2.055-1.589-3.933-3.356-2.594L29.204 44.042c-.157.207-.197-.913-.169-1.172l5.426-49.679c-.41-2.196-3.453-2.474-4.204-.384L25.51 43.046c-.027.259-.85-.144-.96-.379L3.717-1.949C2.188-3.588-.502-2.178 0 0z"
              ></path>
            </g>
          </g>
        </g>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(70.914 171.165 32.677)"
          writingMode="lr-tb"
        >
          <tspan x="0 3.0699673 5.464942 7.8199167 9.0799036 10.614887" y="0">
            Health
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(35.206 256.175 131.916)"
          writingMode="lr-tb"
        >
          <tspan x="0 1.3550187 3.815053 6.1150846 8.6051197 12.31517" y="0">
            Income
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(1.402 3915.353 4561.368)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.454998 4.9149961 7.1599941 8.4199934 9.9904919 12.48049 14.940488 18.650484 21.045483 23.505482"
            y="0"
          >
            Environment
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(-33.506 -50.208 -233.506)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.8499742 5.3399515 9.0499182 12.759884 15.219862 17.67984 18.939829 20.474815"
            y="0"
          >
            Community
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(-1 1) rotate(-66.215 -27.149 140.407)"
          writingMode="lr-tb"
        >
          <tspan x="0 2.4499824 4.9399648 7.414947" y="0">
            Jobs
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(85.176 116.349 59.907)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.4550066 4.9350133 7.3950195 9.6950254 12.050033 13.585036 14.845039 17.335047"
            y="0"
          >
            Education
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(53.825 163.812 137.707)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.8500156 4.1100225 6.3550348 7.6150417 9.9150543 11.065061 13.520074 15.980088 18.495102 20.850115 23.365128 25.760141 29.470161 31.865175 34.325188"
            y="0"
          >
            Civic Engagement
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(21.442 319.535 449.652)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.9849784 5.2849617 7.5849452 9.979928 12.249911 13.399903 14.863393 17.353374 18.503366 20.77335 23.168333 24.867821 27.112804 28.372795 30.672777 33.06776"
            y="0"
          >
            Acces to services
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(-8.896 -543.557 -1268.071)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 2.3949742 3.6549606 5.2949429 7.6899171 8.8399048 11.10988 13.464855 14.999839 16.259825 18.5298 20.169783 22.524757 24.824734 26.359716 27.619703 30.109676"
            y="0"
          >
            Life satisfaction
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(1 -1) rotate(-40.173 -67.245 -320.501)"
          writingMode="lr-tb"
        >
          <tspan
            x="0 3.0700042 5.560008 8.0200109 10.290014 11.550016 14.010019"
            y="0"
          >
            Housing
          </tspan>
        </text>
        <text
          style={{ InkscapeFontSpecification: "RobotoCondensed-Bold" }}
          fill="#fff"
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          fontFamily="Roboto Condensed"
          fontSize="5"
          fontStretch="condensed"
          fontVariant="normal"
          fontWeight="bold"
          transform="scale(-1 1) rotate(-74.919 7.653 187.255)"
          writingMode="lr-tb"
        >
          <tspan x="0 2.6999996 5.0549994 6.6949992 9.0899992 10.624999" y="0">
            Safety
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default OriginsSchema
