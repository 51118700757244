import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"
import ReactMarkdown from "react-markdown"

import SEO from "../components/seo"
import Layout from "../components/Layout"
import InnovationExplorer from "../components/InnovationExplorer"
import PageIntro from "../components/PageIntro"

import OriginsSchema from "../components/OriginsSchema"

export const MethodologyPageTemplate = ({
  methodology_intro,
  origins,
  dimensions,
  children,
  isPreview = false,
  fluidImage,
  fluidImageB,
}) => {
  const [activeDimension, setActiveDimension] = useState(0)

  return (
    <MethodologyWrapper>
      <section className="page-header">
        {fluidImageB && (
          <div className="page-header__background">
            <ImageBackground
              Tag="div"
              fluid={fluidImageB}
              style={{
                backgroundPosition: "bottom left",
                backgroundSize: "100% auto",
              }}
            />
          </div>
        )}
        <div className="container">
          <div className="inner-container">
            <div className="columns">
              <div className="column column-6">
                <PageIntro {...methodology_intro} />
              </div>
              <div
                className="column column-6"
                style={{ marginBottom: 0, position: "relative" }}
              >
                {fluidImage && (
                  <ImageBackground
                    Tag="div"
                    fluid={fluidImage}
                    style={{
                      backgroundPosition: "top left",
                      backgroundSize: "contain",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="origins">
        <div className="container">
          <div className="inner-container">
            <h2>{origins.heading}</h2>
            <div className="columns">
              <div className="column column-6">
                <ReactMarkdown source={origins.text_block_1} />
              </div>
              <div className="column column-6">
                <ReactMarkdown source={origins.text_block_2} />
              </div>
            </div>
            <div className="columns">
              <div className="column column-6">
                <h3>{origins.components_title}</h3>
                <ol className="components">
                  {origins.components.map((component, i) => (
                    <li key={i}>
                      <span class="marker">{i + 1}</span>
                      {component.text}
                    </li>
                  ))}
                </ol>
              </div>
              <small className="column column-6 small">
                <ReactMarkdown source={origins.small_text} />
              </small>
            </div>
            <h2 className="schema-title">{origins.schema_heading}</h2>
            <div className="schema">
              <OriginsSchema />
            </div>
            <small className="grey">{origins.small_text_2}</small>
          </div>
        </div>
      </section>
      {!isPreview && <InnovationExplorer />}
      <section className="dimensions-section">
        <div className="container">
          <div className="box">
            <div className="inner-container">
              <div>
                <ul className="dimensions">
                  {dimensions.map((dim, i) => (
                    <li key={dim.title}>
                      <div
                        className="icon"
                        onClick={() => setActiveDimension(i)}
                      >
                        <img src={dim.icon} alt={`${dim.title} icon`} />
                      </div>
                      <div className="dimension-title">{dim.title}</div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="dimension-content">
                <div>
                  <ReactMarkdown source={dimensions[activeDimension].content} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MethodologyWrapper>
  )
}

const Methodology = ({
  data: {
    markdownRemark: { frontmatter },
    backgroundImage,
    backgroundImageB,
  },
}) => {
  const fluidImage = get(backgroundImage, "childImageSharp.fluid")
  const fluidImageB = get(backgroundImageB, "childImageSharp.fluid")
  return (
    <Layout>
      <SEO title={"Methodology"} />
      <MethodologyPageTemplate
        {...frontmatter}
        fluidImage={fluidImage}
        fluidImageB={fluidImageB}
      ></MethodologyPageTemplate>
    </Layout>
  )
}

const ImageBackground = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
`

const MethodologyWrapper = styled.div`
  .page-header {
    position: relative;
    background: rgba(90, 136, 159, 0.05);
    overflow: hidden;
    padding: 2.5rem 0;

    @media screen and (min-width: ${({ theme }) => theme.tablet}) {
      padding: 7.5rem 0;
    }

    &__background {
      display: none;
      position: absolute;
      width: 50%;
      height: calc(100% - 5.5rem);
      top: 0;
      left: 50%;
      margin-top: 5.5rem;

      @media (min-width: 1100px) {
        display: block;
      }
    }

    .content-b {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .origins {
    background-color: rgb(222, 231, 238);
    padding-top: 3rem;
    padding-bottom: 2rem;
    .inner-container {
      h2 {
        margin-bottom: 1.5rem;

        &.schema-title {
          text-align: center;
          margin-top: 2.5rem;
        }
      }

      h3 {
        font-size: 1rem;
        margin-top: 1.5rem;
      }

      p {
        font-family: ${({ theme }) => theme.fonts["vollkorn"]};
      }

      ol.components {
        margin-top: 1rem;
        li {
          position: relative;
          padding-left: 3rem;
          font-size: 0.75rem;
          .marker {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 2rem;
            width: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgb(2, 68, 133);
            color: white;
            font-weight: bold;
            border-radius: 50%;
          }
          &:not(:last-child) {
            margin-bottom: 1.5rem;
          }
        }
      }

      small {
        p,
        ul,
        li {
          font-family: ${({ theme }) => theme.fonts["roboto"]};
          font-size: 0.75rem;
        }
        ul {
          padding-left: 1rem;
          list-style-type: disc;
        }
      }

      small.grey {
        margin-top: 2.5rem;
        display: block;
        font-size: 0.75rem;
        text-align: center;
        color: #555;
      }
    }

    .schema {
      margin: auto;
      width: 100%;
      /* aspect-ratio: 474/422; */
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .dimensions-section {
    background: #f6f6f6;
    padding: 5rem 0;
    .container {
      .box {
        padding: 3rem 0;
        background-color: white;
        border-radius: 1rem;
        box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.3);
        .inner-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 3.5rem;
          row-gap: 2rem;

          @media (max-width: 900px) {
            grid-template-columns: 1fr;
          }
          .dimensions {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 1rem;
            column-gap: 1.5rem;
            @media (max-width: 400px) {
              column-gap: 0.25rem;
            }
            li {
              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                width: 150px;
                height: 150px;
                border-radius: 12px;
                transition: all ease 0.23s;
                &:hover {
                  cursor: pointer;
                  filter: brightness(60%);
                }
                @media (max-width: 750px) {
                  width: 80px;
                  height: 80px;
                }
                @media (max-width: 400px) {
                  width: 60px;
                  height: 60px;
                }
                img {
                  max-width: 80%;
                  max-height: 70%;
                  object-fit: contain;
                }
              }
              .dimension-title {
                text-align: center;
                line-height: 1.2;
                margin-top: 0.45rem;
                font-weight: 500;
              }

              &:nth-child(2n) .icon {
                background-color: rgb(136, 49, 122);
              }

              &:nth-child(2n + 1) .icon {
                background-color: rgb(27, 131, 143);
              }
            }
          }

          .dimension-content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.5rem;
            & > div {
              h2,
              h3 {
                margin-bottom: 1.5rem;
              }

              ul {
                list-style-type: disc;
                padding-left: 1rem;
                li {
                  font-family: ${({ theme }) => theme.fonts["vollkorn"]};
                }
              }
            }
          }
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "methodology-page" } }) {
      frontmatter {
        methodology_intro {
          heading
          subtitle
          subheading
          text
        }
        origins {
          heading
          text_block_1
          text_block_2
          small_text
          components_title
          components {
            text
          }
          schema_heading
          small_text_2
        }
        dimensions {
          icon
          title
          content
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "methodology-bg-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    backgroundImageB: file(relativePath: { eq: "methodology-bg-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Methodology
