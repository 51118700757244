import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import PercentCircle from "./PercentCircle"
import ReactMarkdown from "react-markdown"
import get from "lodash/get"

export const InnovationExplorerTemplate = ({ title, topics }) => {
  const [activeTopic, setActiveTopic] = useState(0)
  const isMobile =
    typeof window !== "undefined"
      ? window.matchMedia("(max-width: 700px)").matches
      : true

  const nbPage = isMobile ? topics.length : Math.ceil(topics.length / 2)

  return (
    <Wrapper len={topics.length} activeTopic={activeTopic}>
      <div className="container">
        <div className="inner-container">
          <h2>{title}</h2>
          <div className="topic-container" style={{}}>
            {topics.map((topic, index) => (
              <section
                key={index}
                id={topic.slug}
                className="topic-section"
                style={{}}
              >
                <div className="topic-section__graphic">
                  <PercentCircle percent={topic.percent} total={topic.total} />
                </div>
                <div className="content">
                  <h3>{topic.title}</h3>
                  <p className="large">{topic.subtitle}</p>
                  <ReactMarkdown source={topic.text} />
                </div>
              </section>
            ))}
          </div>
          <div>
            <div className="buttons">
              <button
                onClick={() => {
                  setActiveTopic(old => old - 1)
                }}
                disabled={activeTopic == 0}
              >
                &lt;
              </button>
              <button
                onClick={() => {
                  setActiveTopic(old => old + 1)
                }}
                disabled={activeTopic == nbPage - 1}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: ${({ theme }) => theme.darkBlue};
  color: white;
  border-bottom: 2px solid ${({ theme }) => theme.offWhite};
  padding: 2.5rem 0 2rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    padding: 5rem 0 5rem;
  }

  h2 {
    ${({ theme }) => theme.isHeadingSize["1"]};
    max-width: 50%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  blockquote,
  a {
    color: white;
  }

  .topic-container {
    display: grid;
    overflow: hidden;
    margin-top: 3rem;
    grid-template-columns: repeat(${props => props.len}, 50%);
    @media (max-width: 700px) {
      grid-template-columns: repeat(${props => props.len}, 100%);
    }
  }

  .topic-section {
    padding: 0 1rem;
    transition: all ease 0.3s;
    transform: translateX(${props => -200 * props.activeTopic}%);

    @media (max-width: 700px) {
      transform: translateX(${props => -100 * props.activeTopic}%);
    }

    h3 {
      ${({ theme }) => theme.isHeadingSize["2"]};
    }

    p.large {
      font-size: 1.375rem;
    }

    &__graphic {
      margin-bottom: 2.75rem;
    }
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: transparent;
      color: white;
      border: none;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding: 4px 8px;
      transition: all ease 0.3s;
      margin: 0;
      &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-left: 1px solid #ccc;
      }
      &:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-right: 1px solid #ccc;
      }
    }
  }
`

const InnovationExplorer = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { slug: { eq: "innovation-explorer" } }) {
        frontmatter {
          title
          topics {
            title
            subtitle
            slug
            text
            percent
            total
          }
        }
      }
    }
  `)
  const { title, topics } = get(data, "markdownRemark.frontmatter")
  if (!topics) return null
  return <InnovationExplorerTemplate title={title} topics={topics} />
}

export default InnovationExplorer
